<template>
  <div class="vehicle-box">
    <div class="vehicle-box-item" :class="{ 'vehicle-box-current': item.vehicleId == checkedData?.vehicleId, 'disabled': item.Z > 5 }" v-for="item,index in realityVehicleList" :key="index" @click="handleCheck(item)">
      <!-- <img :src="11" alt="" srcset=""> -->
      <Iconfont class="vehicle-box-item-icon" :size="48" :name="item.imageUrl"></Iconfont>
      <div class="vehicle-box-item-center">
        <div class="vehicle-box-item-center-name">{{ item.P }}</div>
        <div class="vehicle-box-item-center-group">车组：{{ item.groupName }}</div>
        <div class="vehicle-box-item-center-status" :class="[ (item.Z == 0) ? 'vehicle-box-item-center-status0' : (item.Z == 1 ? 'vehicle-box-item-center-status2'  : 'vehicle-box-item-center-status' + item.Z) ]">{{ getStateLabel(item.Z, item.isCommercialVehicles, item.online, item.ACC) }}</div>
        <img v-if="item.isSupportMedia" src="../../assets/images/realTimePositioning/sssp.png" :style="{filter: `grayscale(${item.mediaOnline ? '0%' : '100%'})`, cursor: item.mediaOnline ? '' : 'no-drop' }" class="vehicle-box-item-center-icon" alt="" srcset="">
      </div>
    </div>
    <div class="vacancy" v-if="!realityVehicleList || !realityVehicleList.length">
      <img class="vacancy-icon" src="../../assets/images/realTimePositioning/zwsj.png" alt="" srcset="">
      <div class="vacancy-text">列表暂无数据</div>
    </div>
  </div>
</template>

<script>
import vehiclePicture from "@/utils/vehiclePicture";
import { isNumber } from "lodash";
export default {
  props: {
    vehicleData: {
      type: Array,
      default: ()=>[]
    },
    checkedData: {
      type: Object,
      default: ()=>{}
    },
    vehicleType: {
      type: [String,Number],
      default: 0
    }
  },
  watch: {
    checkedData: {
      handler(v) {
      },
      deep: true
    },
    vehicleData: {
      handler(v) {
        if (JSON.stringify(this.aisleList) == JSON.stringify(v)) {
          return false;
        }
        this.aisleList = v;
      },
    },
    vehicleType: {
      handler(v) {
      },
    },
    realityVehicleList: {
      handler(v, old) {
        if (JSON.stringify(old) == JSON.stringify(v) && this.checkedData) {
          return false;
        }
        let vehicleInd = -1;
        if (v.length && this.checkedData && this.checkedData.vehicleId) {
          vehicleInd = v.findIndex(val=> val.vehicleId == this.checkedData.vehicleId)
        }else if (v.length) {
          vehicleInd = v.findIndex((obj) => isNumber(obj.X) && isNumber(obj.Y))
        }
        v.length ? this.handleCheck(v[vehicleInd == -1 ? 0 : vehicleInd], true) : '';
      },
      deep: false
    }
  },
  data() {
    return {
      aisleList: [],
				carImgList: [
					'aerial_work_machinery1',
					'aerial_work_machinery2',
					'compaction_machinery',
					'excavating_machinery',
					'excavating_machinery1',
					'hoisting_machinery',
					'hoisting_machinery3',
					'mining_machinery',
					'piling_machinery1',
					'shovel_transport_machinery0',
					'shovel_transport_machinery1',
					'shovel_transport_machinery3',
					'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
				]
    }
  },
  computed: {
    realityVehicleList() {
      if (this.vehicleType == 0) {
        this.$nextTick(()=>{
          let vehicleList = this.aisleList.filter((obj) => isNumber(obj.X) && isNumber(obj.Y))
          this.$emit('loaded', this.aisleList);
        })
        return this.aisleList;
      }else if (this.vehicleType == 1) {
        let vehicleList = this.aisleList.filter(v=> [0, 1].includes(v.Z));
        this.$nextTick(()=>{
          this.$emit('loaded', vehicleList);
        })
        return vehicleList
      }else if (this.vehicleType == 2) {
        let vehicleList = this.aisleList.filter(v=> [4,5].includes(v.Z))
        this.$nextTick(()=>{
          this.$emit('loaded',vehicleList );
        })
        return vehicleList;
      }else if (this.vehicleType == 3) {
        let vehicleList = this.aisleList.filter(v=> v.BD && v.BD.length)
        this.$nextTick(()=>{
          this.$emit('loaded', vehicleList);
        })
        return vehicleList;
      }
    }
  },
  methods: {
    handleCheck(e , flag) {
      if ((e.Z > 5 || (this.checkedData?.vehicleId == e.vehicleId && this.checkedData?.Z == e.Z)) && !flag) return false;
      // e.isVideo = e.productType === 1;
      this.$emit('check-change',  e, true );
    },
    getStateLabel(e, isCommercialVehicles, online, acc) {
      // if (isCommercialVehicles && e == 2) return '开工';
      // else if (isCommercialVehicles && e == 4) return '停工';
      // else if (isCommercialVehicles && e == 5) return '离线';
      // if (e === 0) return '怠速';
      // if (e === 1) return '行驶';
      // if (e === 2) return '作业';
      // if (e === 3) return '通电';
      // if (e === 4) return '熄火';
      // if (e === 5) return '离线';
      
      if (e === 0) return '停工';
      if (e === 1) return '开工';
      if (e === 5) return '离线';
      return "从未上线";
      
      return '未知';
    },
    async getCarIcon(val, ind, list) {
      let url = await vehiclePicture(
          val.machineryProductType,
          val.machineryEquipmentType,
          val.Z
      );
      list[ind].iconcon = url;
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="scss" scoped>
.vehicle-box {
  overflow-y: auto !important;
  .disabled {
    cursor: no-drop;
  }
  .vacancy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    &-icon {
      width: 121px;
      height: 94px;
    }
    &-text {
      font-size: 15px;
      color: #333333;
      margin-top: 5px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 4px;
    &-icon {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      ::v-deep img {
        border-radius: 4px !important;
        margin: 0 !important;
      }
    }
    &-center {
      position: relative;
      flex: 1;
      // height: 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-name {
        white-space:normal;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      &-group {
        white-space:normal;
        font-size: 14px;
        color: #333333;
        margin-top: 8px;
      }
      &-status {
        position: absolute;
        top: 0;
        right: 0;
        // width: 40px;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        text-align: center;
        font-size: 12px;
        color: #999999;
        background: #EEEEEE;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #E1E1E1;
      }
      &-status0 {
        color: #FCA118;
        background: #FFF5E7;
        border: 1px solid #FFE4BD;
      }
      &-status2 {
        color: #00BC0D;
        background: #E5F8E6;
        border: 1px solid #B7EDBA;
      }
      &-status3 {
        color: #3470FF;
        background: #EAF0FF;
        border: 1px solid #C5D6FF;
      }
      &-icon {
        position: absolute;
        bottom: -4px;
        right: 0;
        width: 17px;
        height: 20px;
      }
    }
  }
  &-item:hover {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px rgba(0,128,255,0.2);
    border-radius: 4px;
    .vehicle-box-item-center {
      &-name {
        color: #0080FF;
      }
    }
  }
  &-current {
    background: #EFF7FF;
    box-shadow: 0px 0px 10px 1px rgba(0,128,255,0.2);
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #0080FF;
    .vehicle-box-item-center {
      &-name {
        color: #0080FF;
      }
    }
  }
}
</style>