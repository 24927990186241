<template>
  <SideLayout style="background-color: rgba(246, 248, 249, .8);padding:16px;width:380px">
    <template #topTitle>
      <div class="topHeader">
        <label>地图检测</label>

        <!-- <el-button v-if="groupIds.length"
          type="text"
          @click.stop="clearAllGroup">取消所有</el-button> -->
      </div>
    </template>

    <template #top>
      <!-- 条件区域 -->
      <div class="form-cell" style="border-bottom: 1px solid #F5F5F5;">
        <div class="form-cell-label">所属车组</div>
        <div class="form-cell-center">
          <SelectGroupTree :multipleProp="{groupCheckBox: true}" @on-confirm="onCheckGroup" placeholder="请选择" />
        </div>
      </div>
      <div class="form-cell">
        <div class="form-cell-label">机械类型</div>
        <div class="form-cell-center">
          <el-select
            v-model="isCommercialVehicles" style="width: 100%;" @change="handleChangeSelect"
            placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </template>
    <template #bottom>
      <!-- 车辆列表区域 -->
      <VehicleList ref="vehicleRef"
        :showChannel="false"
        :groupIds="groupIds"
        :isCommercialVehicles="isCommercialVehicles"
        :onlyVideo="onlyVideo" :isCustomization="isCustomization"
        :updateVehicleTime="updateVehicleTime"
        checkOnNewGroup
        @node-click="onVehicleClick"
        @node-check="onVehicleCheck"
        @check-all="onCheckAll"
        @search-select="onSelectGroup"
        @updateVehicles="$emit('updateVehicles',$event)">
        <template v-if="$scopedSlots.vehicleAction"
          #action="{data}">
          <slot name="vehicleAction"
            :data="data"></slot>
        </template>
      </VehicleList>
    </template>
  </SideLayout>
</template>

<script>

import SideLayout from './SideLayout.vue';
import GroupTree from '@/components/GroupTree';
import VehicleList from '@/components/VehicleList/NewVehicleList';
import SelectGroupTree from "@/components/GroupTree/NewSelectGroupTree";

export default {
  name: 'RealTimeSide',
  components: {
    SideLayout,
    GroupTree,
    VehicleList,
    SelectGroupTree,
  },
  emits: [
    // 车组复选框变化时触发
    'group-check',
    // 车辆点击事件
    'vehicle-click',
    // 车辆复选框变化事件
    'vehicle-check',
    // 车辆全选事件
    'vehicle-check-all',
  ],
  props: {
    // 只显示视频设备
    onlyVideo: {
      type: Boolean,
      default: false,
    },
    /**
  * 车辆更新时间
  * 0: 不更新
  */
    updateVehicleTime: {
      type: Number,
      default: 0,
      validator(val) {
        return val === 0 || val >= 15;
      }
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 勾选的车组Id数组
      groupIds: [],
      isCommercialVehicles: '',
      options: [{
          value: '',
          label: '全部'
        }, {
          value: '0',
          label: '工机'
        }, {
          value: '1',
          label: '商车'
        }],
    };
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {
    clearAllGroup() {
      const { treeRef, vehicleRef } = this.$refs;
      if (!treeRef) return;
      treeRef.cancelAll();
    },
    // async onCheckGroup(data, checked, allCheckeds, node) {
    //   const { treeRef, vehicleRef } = this.$refs;
    //   if (!treeRef) return;

    //   if (checked) {

    //     // 勾选上级节点, 同时勾选所有下级节点
    //     treeRef
    //       .getSubNodes(node, true)
    //       .forEach(subNode => {
    //         treeRef.setChecked(subNode, true, true);
    //       });

    //     await this.$nextTick();
    //   }

    //   // 获取所有勾选的节点
    //   allCheckeds = treeRef.getCheckedNodes();

    //   this.groupIds = allCheckeds.map(group => group.groupId);

    //   this.$emit('group-check', allCheckeds);
    // },

    async onCheckGroup(data, checked, allCheckeds, node) {
      this.groupIds = data.map(group => group.groupId);

      this.$emit('group-check', data);
    },
    handleChangeSelect(e) {
      this.$emit('type-change', e);
    },
    /**
     * 搜索选择事件
     */
    async onSelectGroup(data) {
      this.$umaApi.umaVehicleSelection('搜索选择车辆', data);
      if (data.vehicleId) {
        // await this.onVehicleCheck(data, true, data, [data])
        await this.onVehicleClick(data, true, data, [data]);
        return false;
      }
      const { treeRef } = this.$refs;
      const { groupId } = data;
      // treeRef && treeRef.setCheckedKey(groupId, true);
      treeRef && treeRef.scrollIntoView(groupId);
      this.groupIds.includes(groupId) ? '' : this.groupIds.push(groupId);
    },
    onVehicleClick(data) {
      this.$umaApi.umaVehicleSelection('选择车辆', data);
      // console.log(111);
      this.$emit('vehicle-click', data);
    },
    onVehicleCheck(data, checked, node, allCheckeds) {
      this.$umaApi.umaVehicleSelection('选择车辆', data);
      // console.log(111);
      // this.$umaApi.umaVehicleSelection('单个勾选', data, checked, node, allCheckeds);
      this.$emit('vehicle-click', data, checked, node, allCheckeds);
    },
    onCheckAll(checked, allCheckeds) {
      // console.log(111);
      // this.$umaApi.umaVehicleSelection('批量候选', checked, allCheckeds);
      this.$emit('vehicle-check-all', checked, allCheckeds);
    },
  }
}
</script>

<style lang="scss" scoped>
.RealTimeSideWrapper {
}
.topHeader {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  background: #FFFFFF;
  border-radius: 4px;
}
.form-cell {
  display: flex;
  align-items: center;
  padding: 12px 0;
  padding-left: 8px;
  &-label {
    font-size: 14px;
    color: #333333;
    padding: 2px 8px;
    white-space: nowrap;
    border-right: 1px solid #DEE3E6;
  }
  &-center {
    flex: 1;
    padding: 0 8px;
    overflow: hidden;
    ::v-deep .el-input__inner{
      border: none !important;
      padding: 0;
    }
  }
}
</style>