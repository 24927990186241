<template>
  <div class="SideLayout">
    <div :class="['groupPart', expanded && 'isExpanded']">
      <div class="groupPartHeader">
        <div class="GroupTitle">
          <template v-if="$scopedSlots.topTitle">
            <slot name="topTitle"></slot>
          </template>
          <label v-else>工程机械列表</label>
        </div>
        <div class="height-switch" @click="toogleExpanded">
          {{ expanded ? '收起' : '展开' }}
          <div style="width:8px"></div>
          <ElIcon
            v-if="expanded" :size="10" color="#999"
            name="el-icon-arrow-up"
          ></ElIcon>
          <ElIcon v-else name="el-icon-arrow-down" :size="10" color="#999"></ElIcon>
        </div>
      </div>
      <div v-show="expanded" class="groupPartContent">
        <slot name="top"></slot>
      </div>
    </div>
    <div class="vehiclePart">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
/**
 * 侧边栏布局
 */

export default {
  name: 'SideLayout',
  data() {
    return {
      expanded: true,
    };
  },
  computed: {

  },
  methods: {
    toogleExpanded() {
      this.expanded = !this.expanded;
    }
  }
}
</script>

<style lang="scss" scoped>
.SideLayout {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.groupPart {
  min-height: 48px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
}
.vehiclePart {
  flex: 1;
  overflow: hidden;
  overflow: hidden;
  margin-top: 3px;
}
.groupPartHeader {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #F5F5F5;
}
.height-switch {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333333;
  cursor: pointer;
}
.groupPartContent {
  flex: 1;
  overflow: hidden;
  margin-top: 2px;
}
.GroupTitle {
  color: #1e1e1e;
  font-weight: 700;
  font-size: 16px;
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
}
.isExpanded {
  // flex: 1;
}
</style>