<template>
  <!-- <el-radio-group class="FilterButton" size="medium" v-model="type">
    <el-radio-button :label="0" :style="{width: isCustomization ? '50%' : '25%'}">
      <div class="TypeItem">
        <span>全部</span>
        <span>({{total}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="1" :style="{width: isCustomization ? '50%' : '25%'}">
      <div class="TypeItem">
        <span>在线</span>
        <span>({{online}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="2" v-if="!isCustomization">
      <div class="TypeItem">
        <span>离线</span>
        <span>({{offline}})</span>
      </div>
    </el-radio-button>
    <el-radio-button :label="3" v-if="!isCustomization">
      <div class="TypeItem">
        <span>视频故障</span>
        <span>({{videoAlarm}})</span>
      </div>
    </el-radio-button>
  </el-radio-group> -->
  <div class="FilterButton">
    <div class="FilterButton-item" @click="type = item.value" :class="{'FilterButton-current': type == item.value}" v-for="item,index in typeList" :key="index">
      <div class="FilterButton-item-top">
        <img src="../../assets/images/realTimePositioning/car-qb.png" v-if="item.icon == 'qb'" class="FilterButton-item-top-icon" alt="" srcset="">
        <img src="../../assets/images/realTimePositioning/car-zx.png" v-else-if="item.icon == 'zx'" class="FilterButton-item-top-icon" alt="" srcset="">
        <img src="../../assets/images/realTimePositioning/car-lx.png" v-else-if="item.icon == 'lx'" class="FilterButton-item-top-icon" alt="" srcset="">
        <img src="../../assets/images/realTimePositioning/car-gz.png" v-else-if="item.icon == 'sp'" class="FilterButton-item-top-icon" alt="" srcset="">
        <div class="FilterButton-item-top-name">{{ item.label }}</div>
      </div>
      <div class="FilterButton-item-num">{{ countObj[item.key] || 0 }}</div>
    </div>
  </div>
</template>

<script>

export const FilterType = {
  isAll: 0,
  isOnline: 1,
  isOffline: 2,
  isVideoAlarm: 3,
};

export default {
  name: 'FilterButtom',
  model: {
    prop: 'filterType',
  },
  props: {
    filterType: {
      type: Number,
      default: FilterType.isAll,
    },
    total: {
      type: Number,
      default: 0,
    },
    online: {
      type: Number,
      default: 0,
    },
    offline: {
      type: Number,
      default: 0,
    },
    videoAlarm: {
      type: Number,
      default: 0
    },
    countObj: {
      type: Object,
      default: ()=>{}
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: this.isCustomization ? 1 : FilterType.isAll,
      typeList: [
        {
          label: '全部',
          value: 0,
          icon: 'qb',
          key: 'totalSize',
        },
        {
          label: '在线',
          value: 1,
          icon: 'zx',
          key: 'onlineNum',
        },
        {
          label: '离线',
          value: 2,
          icon: 'lx',
          key: 'offlineNum',
        },
        {
          label: '视频故障',
          value: 3,
          icon: 'sp',
          key: 'bkdCount',
        }
      ]
    };
  },
  watch: {
    filterType(val) {
      this.type = val;
    },
    type(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.FilterButton {
  width: 100%;
  display: flex;
  align-items: center;
  &-item {
    position: relative;
    flex: 1;
    padding: 16px 0;
    cursor: pointer;
    &-top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      font-size: 12px;
      color: #333333;
      &-icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
      &-name {
        padding-top: 3px;
      }
    }
    &-num {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-top: 6px;
    }
  }
  &-current {
    .FilterButton-item-num {
      color: #0080FF;
    }
  }
  &-current::before {
      content: '';
      position: absolute;
      left: 4px;
      right: 4px;
      bottom: 0;
      height: 4px;
      background: #0080FF;
    }
}
.TypeItem {
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  color: #333333;
}

::v-deep .el-radio-button {
  width: 25%;
  .el-radio-button__inner {
    width: 100%;
  }
}
</style>