<template>
  <div class="VehicleListWrapper">
    <div class="VehicleListWrapper-type">
       <!-- 筛选 -->
      <FilterBottom v-if="showFilter" :isCustomization="isCustomization"
        v-model="filterType"
        :countObj="countObj"
        :total="totalCount"
        :online="onlineCount"
        :offline="offlineCount"
        :videoAlarm="bkdCount"></FilterBottom>

      <!-- 搜索 -->
      <GroupSearch v-if="showSearch" :showGroup="false" inputStr="请输入设备名称"
        @select="onSerachSelect"></GroupSearch>
    </div>


    <!-- 车辆列表 -->
    <div v-loading="treeLoading"
      class="VehicleTree">
      <!-- 全选控制 -->
      <!-- <div class="treeHeader">
        <el-button type="text"
          @click.stop="onCheckAll(true)">全选</el-button>
        <el-button type="text"
          @click.stop="onCheckAll(false)">取消全选</el-button>
      </div> -->

      <!-- 列表 -->
      <VirtualCar class="treeConent"
        :showCheckbox="true"
        :emptyText="emptyText" :isCustomization="isCustomization"
        :vehicleData="visibleList"
        :checkedData="checkedData"
        :defaultExpandKeys="defaultExpandKeys"
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultDisabledKeys="defaultDisabledKeys"
        checkOnClickNode
        :visibleFilter="treeVisibleFilter"
        :vehicleType="filterType"
        :rootParentKey="null"
        :indent="26"
        :fieldNames="{
          key: 'key',
          parentKey: 'parentKey',
          label: 'label'
        }"
        @loaded="onTreeLoaded"
        @node-click="onNodeClick"
        @check-change="onCheckChange"
        @node-expand="onNodeExpand">
      </VirtualCar>
    </div>
  </div>
</template>

<script>
import { isUndefined, throttle } from "lodash";
import VirtualTree from "@/components/VirtualTree";
import GroupSearch from "@/components/GroupSearch";
import VehicleStateLabel from "@/components/VehicleStateLabel";
import FilterBottom, { FilterType } from "./NewFilterButton.vue";
import VirtualCar from "./VirtualCar.vue";
import { loadVehicles } from "@/api/live-monitor-api";
import { loadVehicles as loadVehiclesZSY } from "@/api/foreign";
import {
  arrayTreeSort,
  getTreeNodeId,
  TreeNodeType
} from "@/utils/treeHelper.js";
import { diffPatch } from "@/utils/diffPatch";
import NodeIcon from "@/components/Icon/NodeIcon.vue";
import { sleep } from "@/utils/sleep";

export default {
  name: "VehicleList",
  components: {
    VirtualTree,
    GroupSearch,
    FilterBottom,
    NodeIcon,
    VehicleStateLabel,
    VirtualCar
  },
  emits: [
    // 更新倒计时事件
    "countdown",
    // 更新数据
    "updateVehicles",
    // 点击事件
    "node-click",
    // 复选框勾选事件
    "node-check",
    // 选项事件
    "check-all",
    // 节点展开事件
    "node-expand",
    // 搜索选择
    "search-select"
  ],
  props: {
    /**
     * 显示搜索框
     */
    showSearch: {
      type: Boolean,
      default: true
    },
    /**
     * 显示筛选类型button
     */
    showFilter: {
      type: Boolean,
      default: true
    },
    /**
     * 显示通道号
     */
    showChannel: {
      type: Boolean,
      default: true
    },
    /**
     * 车组Id数组
     */
    groupIds: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * 车辆更新时间
     * 0: 不更新
     */
    updateVehicleTime: {
      type: Number,
      default: 0,
      validator(val) {
        return val === 0 || val >= 15;
      }
    },
    // 只显示视频设备
    onlyVideo: {
      type: Boolean,
      default: false
    },
    // 新增车组的车辆默认是否勾选
    checkOnNewGroup: {
      type: Boolean,
      default: false
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    },
    isCommercialVehicles: {
      type: String,
      default: '',
    }
  },
  data() {
    // 定时器句柄Id
    this.timerId = null;
    this.pmt = 0;
    this.vmt = 0;
    // 映射车辆数组
    this.vehicleMap = new Map();

    return {
      emptyText: "--",
      treeLoading: false,
      innerGroupIds: [],
      vehicleList: [],
      visibleList: [],
      filterType: this.isCustomization ? 1 : FilterType.isAll,
      currentKey: "",
      // 默认勾选
      defaultCheckedKeys: [],
      // 默认展开
      defaultExpandKeys: [],
      // 默认禁用复选key数组
      // defaultDisabledKeys: [],
      // 车辆统计(后端)
      countObj: {
        // 总数
        totalCount: 0,
        // 在线
        onlineCount: 0,
        // 离线
        offlineCount: 0,
        // 视频故障
        bkdCount: 0
      },
      checkedData: null,
      checkeId: null,
      timerObj: null,
    };
  },
  computed: {
    defaultDisabledKeys() {
      const { vehicleList } = this;
      return vehicleList
        .filter((obj) => obj.Z > 5)
        .map((obj) => obj.key);
    },
    /**
     * 车辆总数(前端)
     */
    totalCount() {
      const { vehicleList } = this;
      return vehicleList.length;
    },
    /**
     * 车辆在线数, 行驶&停车
     */
    onlineCount() {
      const { vehicleList } = this;
      return vehicleList.filter((obj) => [0, 1, 2, 3, this.isCustomization ? 9999 : 4].includes(obj.Z) && (this.isCustomization ? !obj.BD : true)).length;
    },
    /**
     * 车辆离线数
     */
    offlineCount() {
      // const { totalCount, onlineCount } = this;
      // return totalCount - onlineCount;
      const { vehicleList } = this;
      // return vehicleList.filter((obj) => [5].includes(obj.Z)).length;
      return vehicleList.filter((obj) => obj.Z >= 5).length;
    },
    /**
     * 视频故障数
     */
    bkdCount() {
      const { vehicleList } = this;
      return vehicleList.filter((obj) => !!obj.BD).length;
    }
  },
  watch: {
    groupIds(val) {
      this.innerGroupIds = [...val];
    },
    isCommercialVehicles: {
      async handler(val) {
          this.stopUpdate();
          this.getChangeVehicles();
          this.startUpdate();
      }
    },
    async innerGroupIds(newVal, oldVal) {
      const { vehicleList, vehicleMap } = this;
      const [addIds, updateIds, delIds] = diffPatch(
        newVal,
        oldVal,
        (newId, oldId) => newId === oldId
      );
      // 取消车组
      if (delIds.length > 0) {
        this.vehicleList = vehicleList.filter(
          (item) => !delIds.includes(item.groupId)
        );
        vehicleMap.forEach((obj) => {
          if (delIds.includes(obj.groupId)) {
            vehicleMap.delete(obj.key);

            // 取消勾选
            this.defaultCheckedKeys = this.defaultCheckedKeys.filter(
              (key) => key !== obj.key
            );

            // 取消展开
            this.defaultExpandKeys = this.defaultExpandKeys.filter(
              (key) => key !== obj.key
            );
          }
        });

        if (!newVal.length) {
          this.vehicleList = [];
          
          this.countObj = {
            totalSize: 0,
            onlineNum: 0,
            offlineNum: 0,
            bkdCount: 0
          };
        } else {
          this.stopUpdate();
          this.startUpdate();
          this.getChangeVehicles();
        }
      }
      // 新加车组
      if (addIds.length > 0) {
          this.stopUpdate();
          this.getChangeVehicles();
          this.startUpdate();
      }
      this.setVisibleList();
    },

    updateVehicleTime(val) {
      if (val === 0) {
        // 0: 停止定时更新功能
        this.stopUpdate();
        return;
      }
    },
    // vehicleList: {
    //   handler(v) {
    //     if (v && v.length) {
    //       // this.$emit("check-all", true, v.filter(item => item.Z < 6));
    //       let findData = v.find(val=> val.Z < 6);
    //     }
    //   },
    //   deep: true
    // }
  },
  beforeMount() {
    this.setVisibleList = throttle(this.setVisibleList, 500).bind(this);
  },
  mounted() { },
  activated() {
    if (this.vehicleList && this.vehicleList.length > 0) {
      this.updateVehicles();
      this.stopUpdate();
      this.startUpdate();
    }
  },
  deactivated() {
    this.stopUpdate();
  },
  beforeDestroy() {
    this.stopUpdate();
    this.vehicleList = [];
    this.vehicleMap.clear();
  },
  methods: {
    treeVisibleFilter({ data }) {
      const { filterType } = this;
      if (filterType === FilterType.isAll) return true;
      if (data.nodeType === TreeNodeType.isVehicle) {
        return this.isVisibleVehicle(data);
      }
      return this.isVisibleChannel(data);
    },

    isVisibleChannel(channel) {
      const vehicle = this.vehicleMap.get(channel.parentKey);
      if (vehicle) {
        return this.isVisibleVehicle(vehicle);
      }
      return false;
    },
    isVisibleVehicle(vehicle) {
      const { filterType } = this;
      if (filterType === FilterType.isAll) return true;
      // 在线 1: 行驶; 2: 停车
      // if (filterType === FilterType.isOnline && !this.isCustomization) return [0, 2, 3, 4].includes(vehicle.Z);
      // if (filterType === FilterType.isOnline && this.isCustomization) return [0, 2, 3].includes(vehicle.Z);
      if (filterType === FilterType.isOnline) return [0, 1].includes(vehicle.Z);
      // if (filterType === FilterType.isOnline && this.isCustomization) return [0, 2, 3].includes(vehicle.Z);
      // 离线 0: 离线; 3: 从未上线; 4: 过期
      if (filterType === FilterType.isOffline)
        // return [1, 4, 5, 6].includes(vehicle.Z);
        return [5].includes(vehicle.Z);
      // 视频故障
      if (filterType === FilterType.isVideoAlarm)
        return vehicle.BD && vehicle.BD.length && !this.isCustomization;
      return true;
    },
    setVisibleList() {
      const { vehicleList, filterType, visibleList } = this;
      if (JSON.stringify(visibleList) == JSON.stringify(vehicleList) ) return false;
      this.visibleList = JSON.parse(JSON.stringify(vehicleList));
      this.$forceUpdate();
      // if (filterType === FilterType.isAll) {
      //   this.visibleList = vehicleList;
      //   return;
      // }

      // this.visibleList = vehicleList.filter(obj => {
      //   if (obj.nodeType === TreeNodeType.isVehicle) {
      //     return this.isVisibleVehicle(obj);
      //   }
      //   return this.isVisibleChannel(obj);
      // });
    },
    setCurrentKey(key, intoView = true) {
      this.currentKey = key;
      // this.checkedData = 
    },

    /**
     * 定制更新车辆数据
     */
    stopUpdate() {
      clearInterval(this.timerId);
      this.timerId = null;
    },
    /**
     * 开始更新车辆数据
     */
    startUpdate() {
      if (this.timerObj) {
        clearTimeout(this.timerObj);
      }
      this.timerId = true;
      let count = this.updateVehicleTime;
      this.timerObj = setTimeout(async () => {
        // try {
        //   count--;
        if (!this.timerId) return clearTimeout(this.timerObj);

        //   this.$emit("countdown", count);

        //   if (count <= 0) {
        //     count = this.updateVehicleTime;
        //     this.updateVehicles();
        //   }
        // } catch (err) {
        //   console.error(err);
        // }
        await this.updateVehicles(true);
        clearTimeout(this.timerObj);
        this.timerId && this.startUpdate();
      }, 1000 * count);
    },
    // handlePolling() {

    // },
    async getChangeVehicles() {
      const { innerGroupIds, pmt, vmt, updateVehicleTime } = this;
      try {
        if (updateVehicleTime === 0 || innerGroupIds.length === 0) return;
        let getLoadVehicles = this.$route.name == 'foreignVideoPolling' ? loadVehiclesZSY : loadVehicles;
        this.treeLoading = true;
        const result = await getLoadVehicles({
          groupIds: Array.from(new Set(innerGroupIds)),
          isCommercialVehicles: this.isCommercialVehicles,
        });
        this.treeLoading = false;
        if (result.flag === 1) {
          const {
            pmt,
            vmt,
            data,
            bkdCount, // 视频故障数
            offlineNum, // 离线数
            onlineNum, // 在线数
            totalSize // 总数
          } = result.obj;
          this.pmt = pmt;
          this.vmt = vmt;
          this.countObj = {
            totalSize,
            onlineNum,
            offlineNum,
            bkdCount
          };
          this.$forceUpdate();
          // this.countObj.totalCount = totalSize;
          // this.countObj.onlineCount = onlineNum;
          // this.countObj.offlineCount = offlineNum;
          // this.countObj.bkdCount = bkdCount;
          // if (data.length > 0) {
            // let addList = arrayTreeSort(data, null, {
            //   id: "key",
            //   parentId: "parentKey"
            // });
            // this.vehicleList.splice(0, 0, ...addList);
            this.vehicleList = data || [];
            this.setVisibleList();
          // }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 实时更新车辆数据
    async updateVehicles(flag) {
      const { innerGroupIds, pmt, vmt, updateVehicleTime } = this;

      try {
        if (updateVehicleTime === 0 || innerGroupIds.length === 0) return;
        let getLoadVehicles = this.$route.name == 'foreignVideoPolling' ? loadVehiclesZSY : loadVehicles;
        flag ? '' : this.treeLoading = true;
        const result = await getLoadVehicles({
          groupIds: Array.from(new Set(innerGroupIds)),
          isCommercialVehicles: this.isCommercialVehicles,
          pmt,
          vmt
        });
        this.treeLoading = false;
        if (result.flag === 1) {
          const {
            pmt,
            vmt,
            data,
            bkdCount, // 视频故障数
            offlineNum, // 离线数
            onlineNum, // 在线数
            totalSize // 总数
          } = result.obj;
          this.pmt = pmt;
          this.vmt = vmt;
          this.countObj = {
            totalSize,
            onlineNum,
            offlineNum,
            bkdCount
          };
          this.$forceUpdate();
          // this.countObj.totalCount = totalSize;
          // this.countObj.onlineCount = onlineNum;
          // this.countObj.offlineCount = offlineNum;
          // this.countObj.bkdCount = bkdCount;

          // 已经更新的数据
          // type: Array<[newVehicle, oldVehicle]>
          const hadUpdates = (data || [])
            .map((vehicle) => this.newUpdateVehicle(vehicle))
            // 选出有数据更新的
            .filter((arr) => arr);

          if (hadUpdates.length > 0) {
            // // 是否更新了车辆状态 或 视频报警状态
            const isUpdateState = hadUpdates.some(([newObj, oldObj]) =>
              (!isUndefined(oldObj.Z) && newObj.Z !== oldObj.Z)
              || (!isUndefined(oldObj.BD) && (newObj.BD.length * oldObj.BD.length === 0))
            );

            if (isUpdateState) {
              this.vehicleList = data;
              this.setVisibleList();
              let ddddd = this.vehicleList.find(val=> val.vehicleId == this.checkedData.vehicleId)
              ddddd ? this.onCheckChange(ddddd , true) : '';
            }
            // console.log(hadUpdates,isUpdateState ,'-------------------');
            // this.vehicleList = hadUpdates;
            // let hadUpdateIds = hadUpdates.map(val=> val.vehicleId);
            // this.visibleList.map((val,ind)=> {
            //   if (hadUpdateIds.includes(val.vehicleId)) {
            //     this.visibleList[ind] = hadUpdates[hadUpdateIds.findIndex(val2=> val2 == val.vehicleId)];
            //   }
            // })
            // type: Array<[newVehicle, oldVehicle]>
            this.$emit("updateVehicles", hadUpdates);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    /**
     * 更新车辆
     * @return {[newVehicle, oldVehicle]}
     */
    updateVehicle(newVehicle) {
      const key = getTreeNodeId(newVehicle.M, newVehicle.V);
      if (!this.vehicleMap.has(key)) return null;
      const vehicle = this.vehicleMap.get(key);

      // 存储更新字段
      const oldObj = {};

      // 用于计数
      let count = 0;
      for (let k in newVehicle) {
        if (vehicle[k] === newVehicle[k]) continue;

        oldObj[k] = vehicle[k];

        vehicle[k] = newVehicle[k];
        count += 1;
      }

      // 数据有更新: arr[0] !== arr[1]
      return count > 0 ? [vehicle, oldObj] : null;
    },
    newUpdateVehicle(newVehicle) {
      // let visibleListIds = this.visibleList.map(val=> val.vehicleId);
      // const key = getTreeNodeId(newVehicle.M, newVehicle.V);
      // if (!this.vehicleMap.has(key)) return null;
      const vehicle = this.visibleList.find(val=> val.vehicleId == newVehicle.vehicleId);
      if (!vehicle) return null;

      // 存储更新字段
      const oldObj = {};

      // 用于计数
      let count = 0;
      for (let k in newVehicle) {
        if (vehicle[k] === newVehicle[k]) continue;

        oldObj[k] = vehicle[k];

        vehicle[k] = newVehicle[k];
        count += 1;
      }

      // 数据有更新: arr[0] !== arr[1]
      return count > 0 ? [vehicle, oldObj] : null;
    },

    /**
     * 根据新增的车组, 获取车辆,并将车辆加到列表中
     */
    async getVehicles(groupIds, key) {
      try {
        const { onlyVideo, defaultCheckedKeys, checkOnNewGroup } = this;
        this.treeLoading = true;
        let getLoadVehicles = this.$route.name == 'foreignVideoPolling' ? loadVehiclesZSY : loadVehicles;
        const result = await getLoadVehicles({ groupIds: Array.from(new Set(groupIds)) , isCommercialVehicles: this.isCommercialVehicles,});

        // this.setEmptyText(result?.msg);
        let addList = [];
        let checkData = null;

        if (result.flag === 1) {
          const {
            pmt,
            vmt,
            data,
            bkdCount, // 视频故障数
            offlineNum, // 离线数
            onlineNum, // 在线数
            totalSize // 总数
          } = result.obj;
          this.pmt = pmt;
          this.vmt = vmt;
          
          this.countObj = {
            totalSize,
            onlineNum,
            offlineNum,
            bkdCount
          };

          (data || []).forEach((vehicle) => {
            const [vehicleNode, channels] = this.toVehicleNode(vehicle);
            // 是否默认勾选
            if (
              checkOnNewGroup &&
              !defaultCheckedKeys.some((k) => vehicleNode.key === k)
            ) {
              defaultCheckedKeys.push(vehicleNode.key);
            }
            if (!onlyVideo || vehicleNode.isVideo || vehicleNode.isSupportMedia) {
              if (!this.vehicleMap.has(vehicleNode.key)) {
                this.vehicleMap.set(vehicleNode.key, vehicleNode);
              }
              if (this.checkeId == vehicleNode.vehicleId) {
                this.checkeId = null;
                checkData = vehicleNode;
              }
              addList.push(vehicleNode);
              addList.push(...channels);
            }
          });
        }

        if (addList.length > 0) {
          addList = arrayTreeSort(addList, null, {
            id: "key",
            parentId: "parentKey"
          });
          this.vehicleList.splice(0, 0, ...addList);
          if (checkData && checkData.length) {
            this.$nextTick(() => {
              this.setCurrentKey(checkData[0]);
            });
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.treeLoading = false;
      }
    },
    // 组装车辆节点
    toVehicleNode(vehicle) {
      const { showChannel } = this;

      const isVideo = vehicle.isSupportMedia;
      const channels = [];

      const vehicleNode = {
        // 节点类型: 车辆
        nodeType: TreeNodeType.isVehicle,
        isVideo,
        key: getTreeNodeId(vehicle.M, vehicle.V),
        parentKey: getTreeNodeId(vehicle.M),
        vehicleId: vehicle.V,
        groupId: vehicle.M,
        // 设备名称`P`与`label`映射
        label: vehicle.P,
        // 地址字段
        address: "",
        ...vehicle

        // TODO: 添加必要的响应式字段
      };

      if (showChannel && isVideo) {
        try {
          (JSON.parse(vehicle.camreaLine) || []).forEach((c) => {
            const channelNode = {
              nodeType: TreeNodeType.isChannel,
              groupId: vehicle.M,
              vehicleId: vehicle.V,
              channel: c,
              key: getTreeNodeId(vehicle.M, vehicle.V, c),
              parentKey: getTreeNodeId(vehicle.M, vehicle.V),
              label: `通道${ c }`,
              checkable: false
            };

            channels.push(channelNode);
          });
        } catch (error) {
          //
        }
      }

      return [vehicleNode, channels];
    },

    // 设置无数据的显示文本
    setEmptyText(text = "--") {
      this.emptyText = text;
    },
    // tree加载完成, 非准确
    async onTreeLoaded(data) {
      await this.$nextTick();
      this.treeLoading = false;

      const { currentKey, checkOnNewGroup } = this;
      if (currentKey) {
        this.setCurrentKey(currentKey);
      }
      let checkVehicle = data.filter(item => item.Z < 6);
      if (checkVehicle && checkVehicle.length) {
        this.$emit("check-all", true, checkVehicle);
      }
      // 默认勾选车辆
      if (checkOnNewGroup) {
        // NOTE: 等待渲染完成, 不准确
        await sleep(300);
        this.triggerCheckAll(true);
      }
    },
    // 单击事件
    onNodeClick(data, node) {
      this.setCurrentKey(data.key, false);
      this.$emit("node-click", data, node);
    },
    /**
     * 节点勾选事件
     */
    onCheckChange(data, checked, node, allCheckeds) {
      this.checkedData = checked ? data : null;
      // this.defaultCheckedKeys = allCheckeds.map((obj) => obj.key);
      this.$emit("node-check", data, checked, node, [data]);
    },
    /**
     * 节点展开事件
     */
    onNodeExpand(data, expanded, node, allExpandeds) {
      this.defaultExpandKeys = allExpandeds.map((obj) => obj.key);
      this.$emit("node-expand", data, expanded, node, allExpandeds);
    },
    /**
     * 全选/取消全选事件
     */
    onCheckAll(checked = false) {
      const { treeRef } = this.$refs;
      if (treeRef) {
        treeRef.setCheckedAll(checked);
        this.triggerCheckAll(checked);
      }
    },
    onSerachSelect(data) {
      let that = this;
      this.checkedData = data;
      // 车辆
        const key = getTreeNodeId(data.groupId, data.vehicleId);
        this.setCurrentKey(key);
        // 已经加载的车辆直接返回
        // if (this.visibleList.findIndex(val=> val.vehicleId ==  data.vehicleId) > -1) {
        //   this.onCheckChange(this.visibleList.find(val=> val.vehicleId ==  data.vehicleId), true)
        //   return false;
        // };

      if (this.innerGroupIds.includes(data.groupId)) {
          console.log('已有车组');
        return false;
      }
      // 车组, 或未加载的车辆

      const innerGroupIds = [...this.innerGroupIds, data.groupId];
      this.checkeId = data.vehicleId;
      this.innerGroupIds = innerGroupIds;
      // this.checkedData = data;
      // setTimeout(function () {
      //   that.checkedData = null;
      // });
      this.$emit("search-select", data);
    },
    /**
     * 触发所有已经勾选的事件
     */
    triggerCheckAll(checked = false) {
      const { treeRef } = this.$refs;
      if (!treeRef) return;
      const allCheckeds = treeRef.getCheckedNodes().map((obj) => obj.data);
      this.defaultCheckedKeys = allCheckeds.map((obj) => obj.key);
      this.$emit("check-all", checked, allCheckeds);
    }
  }
};
</script>

<style lang="scss" scoped>
.VehicleListWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  &-type {
    padding: 0 12px;
    background-color: #fff;
    border-radius: 4px;
    ::v-deep .el-input__inner {
      border: none !important;
    }
  }
}
.VehicleTree {
  flex: 1;
  overflow: hidden;
  padding-top: 7px;
  // background-color: #fff;
  display: flex;
  flex-direction: column;
}
.treeHeader {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 12px;
}
.treeConent {
  flex: 1;
  overflow: hidden;
}
.GVNodeContent {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.GVNodeLabel {
  position: relative;
  width: calc(100% - 100px);
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.NodeActionBox {
  max-width: 45%;
  // min-width: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>