<template>
  <div class="RealTimePositioning" :class="{ 'RealTimePositioning-amap-left': vehicleHeight.left == 0 }">
    <RtpMap v-model="currentVehicle" :zoom="18" :gridSize="1"
                  :minClusterSize="realTimeConfig.vehicleMinClusterNum"
                  :vehicleList="vehicleListOnMap"
                  :speakVehicle="currentSpeak"
                  @playClick="handlePlayClick"
                  @speakClick="onSpeakClick">
                  </RtpMap>
      <Split direction="row" style="padding:0px;" @change="handleChangeVehicleHeight($event, 'width')"
      :sideOffset="0.25">
      <template #first>
        <!-- 侧边栏 -->
        <RealTimeSide
          :updateVehicleTime="realTimeConfig.updateVehicleTime"
          ref="RealTimeSide"
          @group-check="onGroupCheck"
          @vehicle-click="onVehicleClick"
          @vehicle-check-all="onVehicleCheckAll"
          @updateVehicles="onUpdateVehicles"
          @type-change="onTypeChange">
          <template #vehicleAction="{ data }">
            <el-dropdown
              placement="right-end"
              @command="onCommandClick($event, data)"
            >
              <Iconfont name="icon-bofang"></Iconfont>
              <el-dropdown-menu slot="dropdown">
                <!-- 视频设备 and 在线 and ACC开 可看视频 -->
                <!-- 中国石油判断 -->
                <el-dropdown-item
                  v-if="data.isVideo"
                  :disabled="
                    !(data.mediaOnline)
                  "
                  :command="1"
                >
                  <Iconfont
                    name="icon-shishishipin-daohang"
                    :mRight="7"
                  ></Iconfont>
                  <span>实时视频</span>
                </el-dropdown-item>
                <el-dropdown-item :command="2">
                  <Iconfont name="icon-guiji" :mRight="7"></Iconfont>
                  <span>轨迹回放</span>
                </el-dropdown-item>
                <el-dropdown-item :command="3">
                  <Iconfont name="icon-xinxifuzhi" :mRight="7"></Iconfont>
                  <span>信息复制</span>
                </el-dropdown-item>
                <el-dropdown-item :command="4">
                  <Iconfont name="icon-woyaoshouquan" :mRight="7"></Iconfont>
                  <span>我要授权</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </RealTimeSide>
      </template>

      <template #second>
        <Split direction="column"
          canMaximize @change="handleChangeVehicleHeight($event, 'height')"
          :padding="0"
          :sideOffset="0.7">
          <template #first>
            <div class="vvvd" :style="videoStyle">
                <Split
                direction="row-reverse"
                :padding="0" @change="handleChangeVehicleHeight($event, 'video')"
                :sideOffset="videoList.length ? 0.5 : 0"
                :showButton="showButton"
              >
                <!-- 视频 -->
                <template #first>
                  <div class="anchor-box">
                    <RtpPlayer :videoList="videoList"
                    :speakVehicle="speakVehicle"
                    :videoGrid="realTimeConfig.videoGrid"
                    :videoCloseTime="realTimeConfig.videoCloseTime"
                    @playStart="onPlayStart"
                    @playEnd="onPlayEnd"
                    @speakEnd="onSpeakEnd"
                    :Z="Z"></RtpPlayer>
                  </div>
                </template>
              </Split>
            </div>
          </template>

          <!-- 底部车辆信息 -->
          <template #second>
            <RtpFooter :style="vehicleHeight" :dataList="checkedVehicleList"></RtpFooter>
          </template>
        </Split>
      </template>
    </Split>
    <!-- 车辆授权 -->
    <AuthCoin
      v-model="autoCoinObj.visible"
      :vehicleList="autoCoinObj.vehicleList"
    ></AuthCoin>

    <div class="videoplayend" v-if="visible">
      <label>提示</label>
      <div class="content">
        <img class="tipicon" src="../../assets/images/tip.png" />
        <span>您已长时间不操作系统，视频已经自动关闭</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { isNumber } from "lodash";
import Split from "@/components/Split";
import RealTimeSide from "@/components/NewRealTimeSide";

import RtpMap from "./RtpMap.vue";
import RtpPlayer from "./RtpPlayer.vue";

import RtpFooter from "./NewRtpFooter.vue";
import { getTreeNodeId } from "@/utils/treeHelper";
import { copyText } from "@/utils/clipboard";
import AuthCoin from "@/components/AuthCoin";

export default {
  // `name`与路由保持一致
  name: "RealTimePositioning",
  components: {
    Split,
    RealTimeSide,
    RtpMap,
    RtpFooter,
    RtpPlayer,
    AuthCoin,
  },
  data() {
    return {
      // 勾选的车辆
      checkedVehicleList: [],
      currentVehicle: null,
      // 实时视频列表
      videoList: [],
      // 当前对讲车辆
      currentSpeak: null,

      // 授权
      autoCoinObj: {
        visible: false,
        vehicleList: [],
      },
      visible: false,
      videoPlate: undefined,
      Z: undefined,
      showButton: false,
      isCommercialVehicles: '',
      vehicleHeight: {
        height: '312px',
        left: '380px',
      },
      videoStyle: {
        width: '100%',
        bottom: '322px',
      }
    };
  },
  computed: {
    ...mapState(["userConfig", "pathVehicle", "userInfo"]),
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },
    vehicleListOnMap() {
      const { checkedVehicleList } = this;
      return checkedVehicleList.filter(
        (obj) => isNumber(obj.X) && isNumber(obj.Y)
      );
    },
    speakVehicle() {
      const { currentSpeak } = this;
      if (!currentSpeak) return null;

      // 终端号
      const device = currentSpeak.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = ["GF-1078", "GB-1078"].includes(currentSpeak.T)
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = currentSpeak.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = currentSpeak.P;
      // 车辆Id
      const vehicleId = currentSpeak.V;
      // 车组Id
      const groupId = currentSpeak.M;

      return {
        device,
        protocolType,
        SpecialSign,
        plate,
        vehicleId,
        groupId,
      };
    },
    isZgsy() {
      return (
        this.$store.state.userRouter?.children?.find(
          (r) => r.code === "zgsy"
        ) && this.$store.state.userInfo.name != "广东一立科技"
      );
    },
  },
  watch: {
    videoList: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
      },
    },
    pathVehicle: {
      handler(v) {
        if (v) {
          let vehicleData = JSON.parse(v);
          this.$nextTick(() => {
            if (
              !this.$refs.RealTimeSide.groupIds ||
              !this.$refs.RealTimeSide.groupIds.includes(vehicleData.groupId)
            ) {
              this.$refs.RealTimeSide.groupIds.push(vehicleData.groupId);
            }
          });
          //  groupIds
          if (this.checkedVehicleList && this.checkedVehicleList.length) {
            let checkedVehicleIds = this.checkedVehicleList.map(
              (val) => val.vehicleId
            );
            if (checkedVehicleIds.includes(Number(vehicleData.vehicleId))) {
              // this.currentVehicle = this.checkedVehicleList.filter(item => item.vehicleId == Number(vehicleData.vehicleId))[0];
              this.SetPathVehicle(null);
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async onCommandClick(command, data) {
      switch (command) {
        case 1: // 实时视频
          this.handlePlayClick(data);
          break;
        case 2: // 轨迹回放
          this.$router.push({
            name: "TrackPlayback",
            params: {
              vehicle: data,
            },
          });
          break;
        case 3: // 复制信息
          const text = [
            `设备名称: ${data.P}`,
            `车组名称: ${data.groupName}`,
            `终端号: ${data.N}`,
            // `经纬度: ${data.X},${data.Y}`,
            `SIM: ${data.SIM}`,
            // `车辆Id: ${data.V}`,
            // `车组Id: ${data.M}`,
          ].join("\r\n");
          console.log("copy", text);

          await copyText(text);
          this.$message.success("复制成功");

          break;
        case 4: // 授权
          this.autoCoinObj.vehicleList = [
            {
              vehicleId: data.V,
              plate: data.P,
              terminalNo: data.N,
              terminalType: data.T,
              groupId: data.M,
            },
          ];

          this.autoCoinObj.visible = true;
          break;
      }
    },
    handlePlayClick(data) {
      this.videoPlate = data.P;
      const videoList = [...this.videoList];
      const channels = this.parseChannels(data.camreaLine);
      const cameraCH = this.parseChannels(data.cameraCH);

      // 终端号
      const device = data.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      const protocolType = ["GF-1078", "GB-1078"].includes(data.T) ? 1 : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = data.P;
      // 车辆Id
      const vehicleId = data.V;
      // 车组Id
      const groupId = data.M;

      channels.forEach((channel, ind) => {
        const key = getTreeNodeId(data.M, data.V, channel);
        if (videoList.some((obj) => obj.key === key)) return;
        videoList.push({
          key,
          channel,
          channelsName: cameraCH ? cameraCH[ind] : "",
          device,
          protocolType,
          SpecialSign,
          plate,
          vehicleId,
          groupId,
        });
      });
      this.videoList = videoList;
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    onGroupCheck(allCheckeds) {
      const { checkedVehicleList, currentVehicle, videoList } = this;
      const groupIds = allCheckeds.map((p) => p.groupId);
      if (groupIds.length > 0 && checkedVehicleList.length > 0) {
        // 当前车辆未包含在选中的车组中
        if (currentVehicle && !groupIds.includes(currentVehicle.M)) {
          this.currentVehicle = null;
        }

        // 留下包含在选中车组中的车辆
        this.checkedVehicleList = checkedVehicleList.filter((obj) =>
          groupIds.includes(obj.M)
        );
        // 留下包含在选中车组的通道
        this.videoList = videoList.filter((obj) =>
          groupIds.includes(obj.groupId)
        );
      } else {
        this.checkedVehicleList = [];
        this.videoList = [];
        this.currentVehicle = null;
      }
    },
    onVehicleClick(data) {
      if ([0,1, 3, 2, 4, 5].includes(data.Z)) {
        this.currentVehicle = data;
      }
    },
    onVehicleCheck(data, checked, node, allCheckeds) {
      // console.log(12321321321, data);
      // 车辆
      // if (data.nodeType === 2) {
        this.checkedVehicleList = allCheckeds.filter((obj) => [0,1, 3, 2, 4, 5].includes(obj.Z));

        if (checked) {
          if ([0,1, 3, 2, 4, 5].includes(data.Z)) {
            this.currentVehicle = data;
          }
        } else if (
          this.currentVehicle &&
          this.currentVehicle.key === data.key
        ) {
          this.currentVehicle = null;
        }
      // }
    },
    onVehicleCheckAll(checked, allCheckeds) {
      const list = allCheckeds.filter((obj) => [0,1, 3, 2, 4, 5].includes(obj.Z));
      // 勾选 取第一个
      if (checked && list.length) {
        if (this.pathVehicle) {
          // this.currentVehicle = list.filter(item => item.vehicleId == Number(JSON.parse(this.pathVehicle).vehicleId))[0];
          this.SetPathVehicle(null);
        } else {
          // this.currentVehicle = list[0];
        }
      } else {
        this.currentVehicle = null;
      }

      this.checkedVehicleList = list;
    },

    onSpeakClick(vehicle) {
      const { currentSpeak } = this;

      if (currentSpeak && vehicle.key !== currentSpeak.key) {
        return this.$message.warning(`请先关闭 ${currentSpeak.P} 对讲`);
      }

      if (currentSpeak && vehicle.key === currentSpeak.key) {
        this.currentSpeak = null;
        return;
      }

      this.currentSpeak = vehicle;
    },

    onPlayStart(channelObj) {
      const { videoList } = this;
      if (!videoList.some((obj) => obj.key === channelObj.key)) {
        videoList.push(channelObj);
      }
    },
    onPlayEnd(channelObj) {
      const { videoList } = this;
      this.videoList = videoList.filter((obj) => obj.key !== channelObj.key);

      if (!this.videoList.length && channelObj.countdownEnd) {
        this.visible = true;
        this.videoPlate = null;
        setTimeout(() => {
          this.visible = false;
        }, 4000);
      }
    },
    onSpeakEnd() {
      this.currentVehicle = null;
    },
    onUpdateVehicles(vehicles) {
      if (!vehicles.length) return;
      //更新后的车辆集合
      let newVehicles = vehicles.map((arr) => arr[0]);
      //更新前的车辆集合
      // const oldVehicles = vehicles.map((arr) => arr[1]);
      if (this.videoPlate) {
        newVehicles = newVehicles.filter((item) => item.P == this.videoPlate);
        if (newVehicles[0]) {
          const { Z } = newVehicles[0];
          this.Z = Z;
        }
      }
      //举例 判断某个车辆的状态 (Z)是否变化
      // newVehicles[0].Z新变化所有的状态
      // if (newVehicles[0].Z != 2) {
      //   console.log(`车辆[0]的车辆状态变化，更新后${newVehicles[0].Z}`);
      // }
    },
    onTypeChange(e) {
      this.isCommercialVehicles = e;
    },
    handleChangeVehicleHeight(e, type) {
      if (type == 'width') {
        switch (e) {
          case 1:
            this.vehicleHeight.left = 0;
            break;
          case 2:
            this.vehicleHeight.left = '380px';
            break;
          case 3:
            this.vehicleHeight.left = '100%';
            break;
        }
        return false;
      }else if (type == 'video') {
        switch (e) {
          case 1:
            // this.vehicleHeight.left = 0;
            this.videoStyle.width = '392px';
            break;
          case 2:
            this.videoStyle.width = 'calc(100% - 380px)';
            // this.vehicleHeight.left = '380px';
            break;
          case 3:
            // this.vehicleHeight.left = '100%';
            break;
        }
        return false;
      }
      
        console.log(e,'----------');
      switch (e) {
        case 1:
          // this.vehicleHeight = { top: '10px', height: 'calc(100% - 12px)' };
          this.vehicleHeight.height = 'calc(100% - 12px)';
          break;
        case 2:
          this.vehicleHeight.height = '312px';
          this.videoStyle.bottom = '322px';
          break;
        case 3:
          this.vehicleHeight.height = 0;
            this.videoStyle.bottom = '10px';
          break;
      }
    },
    ...mapMutations(['SetPathVehicle']),
  }
};
</script>

<style lang="scss" scoped>
// .sidebar {
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// }
.RealTimePositioning {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ::v-deep .amap-toolbar {
    left: 380px !important;
    // right: 20px;
    top: 160px !important;
  }
  ::v-deep .ranging {
    width: 64px;
    left: 380px !important;
  }
  ::v-deep .amap-maptypecontrol {
    left: 444px !important;
    right: initial;
  }
}
.RealTimePositioning-amap-left {
  ::v-deep .amap-toolbar {
    left: 0 !important;
    // right: 20px;
    top: 160px !important;
  }
  ::v-deep .ranging {
    width: 64px;
    left: 0 !important;
  }
  ::v-deep .amap-maptypecontrol {
    left: 64px !important;
    right: initial;
  }
}
 ::v-deep .SplitLayout {
  // .SplitTrigger {
  //   display: none;
  // }
  .SplitMain {
    position: initial;
    .SplitFirst {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 141;
    }
    .RtpFooterWrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 380px;
      // height: 312px;
      width: auto;
      z-index: 141;
    }
    .SplitTrigger {
      position: absolute;
      left: 380px;
      bottom: 0;
    }
    .SplitSecond {
      .SplitFirst {
        position: initial !important;
      }
      .SplitTrigger {
        width: auto !important;
        bottom: 312px;
        right: 0;
      }
    }
    .vvvd {
      position: fixed;
      width: calc(100% - 380px);
      right: 0;
      top: 64px;
      bottom: 322px;
      pointer-events: none;
      .SplitTrigger {
        position: initial !important;
        bottom: 0;
        pointer-events: auto;
      }
      .SplitFirst {
        pointer-events: auto;
      }
      // .SplitSecond {
      //   pointer-events: none;
      // }
    }
    .anchor-box {
      height: 100%;
    }
    .RtpPlayerWrapper {
      // position: absolute;
      // width: 100%;
      // top: 0;
      // z-index: 141;
    }
  }
 }
.videoplayend {
  width: 405px;
  height: 110px;
  background: #fff;
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -70%);

  & > label {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 37px;
    background: #212d3e;
    color: #fff;
    padding: 0 10px;
    line-height: 37px;
  }
}

.content {
  display: flex;
  width: 100%;
  height: 70px;
  line-height: 70px;

  & > span {
    text-align: center;
    color: #262626;
  }
}

.tipicon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 23px 5px 23px 28px;
}
</style>
